<template>
    <hotels-module-component></hotels-module-component>
</template>

<script>
    import HotelsModuleComponent from "@/components/admin/modules/HotelsModuleComponent";

    export default {
        name: "HotelsModule",
        title: "Gestión de Hoteles | Turismo BC",
        components: { HotelsModuleComponent }
    }
</script>

<style scoped>

</style>